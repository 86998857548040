window.onscroll = function () { scrollFunction() };

function scrollFunction() {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        // add class shrink-header to the #header
        document.getElementById("header").classList.add("shrink-header");
    } else {
        // remove class shrink-header from the #header
        document.getElementById("header").classList.remove("shrink-header");
    }
}

// create function to switch placeholder of search input every 3 seconds
function switchPlaceholder() {
    // get placeholder input by class "search-form__input"
    var placeholderElems = document.getElementsByClassName("search-form__input");
    if (placeholderElems.length == 0) {
        return;
    }
    
    var placeholderArray = ["Je cherche une perceuse", "Je cherche une scie", "Je cherche un chalet", "Je cherche des plaques de plâtre"];
    var i = 0;
    setInterval(function () {
        placeholder = placeholderArray[i];

        var placeholderElems = document.getElementsByClassName("search-form__input");
        // on each placeholderElem
        for (var j = 0; j < placeholderElems.length; j++) {
            // set placeholder value
            placeholderElems[j].placeholder = placeholder;
        }

        i++;
        if (i == placeholderArray.length) {
            i = 0;
        }
    }, 3000);
}

// on document ready
document.addEventListener("DOMContentLoaded", function () {
    // call switchPlaceholder function
    switchPlaceholder();
});


$(document).ready(function(){
// TESIAL FOR AUTHENTIFICATION 
$("input[name='have_card']").change(function(){
    have_card = !!parseInt($(this).val());
    //alert(have_card);
    if(have_card){
        $("#know_card_radio").show();
        $("#submit_button").hide();
        know_card = $("input[name='know_card']:checked").val();
        if (typeof know_card !== 'undefined'){
            know_card = !!parseInt(know_card);
            if(know_card){
                $("#card_number_field").show();
                $("#card_postcode_field").show();
                // $("#email_create_field").show();
                $("#submit_button").show();
                $("#text_field").show();
            } else {
                $("#card_number_field").hide();
                $("#card_number").val('');
                $("#card_postcode_field").show();
                $("#email_create_field").show();
                $("#submit_button").show();
                $("#text_field").hide();
            }
        }
    } else {
        $("#know_card_radio").hide();
        $("#card_number_field").hide();
        $("#card_number").val('');
        $("#card_postcode_field").hide();
        $("#card_postcode").val('');
        $("#email_create_field").hide();
        $("#email_create").val('');
        $("#submit_button").show();
        $("#text_field").hide();
    }
});
$("input[name='know_card']").change(function(){
    know_card = !!parseInt($(this).val());
    //alert(know_card);
    if(know_card){
        $("#card_number_field").show();
        $("#card_postcode_field").show();
        // $("#email_create_field").show();
        $("#email_create_field").hide();
        $("#email_create").val('');
        $("#submit_button").show();
        $("#text_field").show();
    } else {
        $("#card_number_field").hide();
        $("#card_number").val('');
        $("#card_postcode_field").show();
        $("#email_create_field").show();
        $("#submit_button").show();
        $("#text_field").show();
    }
});



	$(document).on('submit', '#create-account_form', function(e){
		e.preventDefault();
		submitFunction(ajaxurl);
	});

    $(document).on('submit', '#customer-form', function(e){
        $('#submitIdentity').hide();
        $('#submitAccountPending').show();
	});

    manageGender();
    manageTooltip();    
});

function manageGender(){
    // Intialisation
    gender = $('input[name="id_gender"]:checked');
    if (typeof gender !== 'undefined'){
        id_gender = parseInt(gender.val());
        setGender(id_gender);
    }

    // On change
    $(document).on('change', 'input[name="id_gender"]', function()
	{	
		id_gender = parseInt($(this).val());
        setGender(id_gender);
    }); 
}

function manageTooltip(){
    $('.label-tooltip').tooltip({
        delay: 250,
        html : true
    });
}

function setGender(id_gender){
    $firstname_field_title = $("label[for^='f-firstname']");
    $lastname_field_title = $("label[for^='f-lastname']");

    if(id_gender == 3) {
        $firstname_field_title.html(contactlabel);
        $lastname_field_title.html(companylabel);
    } else {
        $firstname_field_title.html(firstnamelabel);
        $lastname_field_title.html(lastnamelabel);
    } 
}

function submitFunction(ajaxurl)
{

	$('#create_account_error').html('').hide();
    // alert('submitFunction')
	$.ajax({
		type: 'POST',
		url: ajaxurl,
		async: true,
		cache: false,
		dataType : "json",
		headers: { "cache-control": "no-cache" },
		data:
		{
			ajax: true,
            action: 'checkStates',
			have_card: $('input[name=have_card]:checked').val(),
			know_card: $('input[name=know_card]:checked').val(),
			card_number: $('#card_number').val(),
			card_postcode: $('#card_postcode').val(),
			email_create: $('#email_create').val(),
			back: $('input[name=back]').val()
		},
		success: function(jsonData)
		{
			if (jsonData.hasError)
			{
				 var errors = '';
				 for(error in jsonData.errors)
				 	//IE6 bug fix
				 	if(error != 'indexOf')
				 		errors += '<li>' + jsonData.errors[error] + '</li>';
				 $('#create_account_error').html('<ol>' + errors + '</ol>').show();
                // alert('SUCCESS  ERROR');
			}
			else
			{
                // alert('SUCCESS NO ERROR A'+jsonData.cardNumber+' URL '+jsonData.redirecturl);
                var from_order_field = $('input[name=from_order]');
                var from_order = 0;
                if (typeof from_order_field !== 'undefined' && from_order_field.val()){
                    from_order = 1;
                }

                // alert('from_order : '+from_order);

                var url = jsonData.redirecturl;
                var params = {
                    card_number: jsonData.cardNumber,
                    from_order: from_order
                };

                redirectToPost(url, params);

                // window.location.href = jsonData.redirecturl;
			}
		},
		error: function(XMLHttpRequest, textStatus, errorThrown)
		{
            
            alert('ERROR  ERROR : '+XMLHttpRequest+' AAA '+textStatus);
            console.log(XMLHttpRequest);
			// error = "TECHNICAL ERROR: unable to load form.\n\nDetails:\nError thrown: " + XMLHttpRequest + "\n" + 'Text status: ' + textStatus;
			// if (!!$.prototype.fancybox)
			// {
			// 	$.fancybox.open([
			// 	{
			// 		type: 'inline',
			// 		autoScale: true,
			// 		minHeight: 30,
			// 		content: "<p class='fancybox-error'>" + error + '</p>'
			// 	}],
			// 	{
			// 		padding: 0
			// 	});
			// }
			// else
			// 	alert(error);
		}
	});
}

function redirectToPost(url, params) {
    // Create a form element
    var form = document.createElement("form");
    form.setAttribute("method", "post");
    form.setAttribute("action", url);

    // Iterate over the parameters and create hidden input elements
    for (var key in params) {
        if (params.hasOwnProperty(key)) {
            var hiddenField = document.createElement("input");
            hiddenField.setAttribute("type", "hidden");
            hiddenField.setAttribute("name", key);
            hiddenField.setAttribute("value", params[key]);

            form.appendChild(hiddenField);
        }
    }

    // Append the form to the body
    document.body.appendChild(form);

    // Submit the form
    form.submit();
}


// // TESIAL - Manage of stickers
// $(document).ready(function() {

//     // Attach the onResize function to the window resize event
//     // $(window).resize(onResize);

//     var resizeProduct = 0;
//     $('.product-main-images img.mrb-img').on('load', function() {
//         if(!resizeProduct){
//             resizeProduct = 1;
//             onResize($(this),'#product .mrb-product-sticker');
//         }
//     });

//     // Si l'image est déjà chargée dans le cache, déclenchez manuellement l'événement load
//     if (!resizeProduct && $('.product-main-images img.mrb-img').length && $('.product-main-images img.mrb-img').prop('complete')) {
//         $('.product-main-images img.mrb-img').trigger('load');
//     }
    
//     var resizeProductList = 0; 
//     $('.product-miniature img.mrb-img').on('load', function() {
//         if(!resizeProductList){
//             resizeProductList = 1;
//             onResize($(this),'.product-miniature .mrb-product-sticker');
//         }
//     });

//     // Si l'image est déjà chargée dans le cache, déclenchez manuellement l'événement load
//     if (!resizeProductList && $('.product-miniature img.mrb-img').length && $('.product-miniature img.mrb-img').prop('complete')) {
//         $('.product-miniature img.mrb-img').trigger('load');
//     } 

// });

// function onResize(referenceImage, sticker){
//     var theImage = new Image();
//     theImage.src = referenceImage.attr("src");
//     //alert(theImage.src);
//     // Largueur de l'image initiale
//     var imageWidth = theImage.width;
//     //alert(imageWidth);

//     $(sticker).each(function(index) {
//         var targetImage = $(this); 
//         var imageStickerWidth = targetImage.width();
//         var imageStickerHeight = targetImage.height();

//         // Rapport entre limage initiale et celle affichée
//         var targetAspectRatio = referenceImage.width() / imageWidth;
//         var newWidth, newHeight;
//         newWidth = imageStickerWidth  * targetAspectRatio;
//         newHeight = imageStickerHeight * targetAspectRatio;

//         //targetImage.width(newWidth);
//         //targetImage.height(newHeight);
//         //targetImage.show();
//         });
// }

// Product Page Link to Product Description
$(document).ready(function() {

    // For account page
    $(document).ready(function() {
        if (window.innerWidth <= 768 && $('.customer-links').length) {
            var target = $('.page-content');
            var headerHeight = $('.header-top').outerHeight();
            var targetPosition = $(target).offset().top - headerHeight- 20;

            $('html,body').animate({
                scrollTop: targetPosition
            }, 1200);
        }
    });

    $('.more-infos, #mrb-product-stars').click(function(e){
        var target = $(this).attr('href');
        var headerHeight = $('.header-top').outerHeight();
        var targetPosition = $(target).offset().top - headerHeight- 20;

        $('html,body').animate({
            scrollTop: targetPosition
        }, 1200);
    });

    $('body').on('click', '#content-wrapper .product-tabs-nav__link', function(e){
        e.preventDefault(); // Prevent the default anchor behavior
    
        // Get the target element's position
        var target = $(this).attr('href');
        var headerHeight = $('.header-top').outerHeight();
        var targetPosition = $(target).offset().top - headerHeight- 20;
        // Animate the scroll
        $('html, body').animate({
          scrollTop: targetPosition
        }, 1000); // 1000 milliseconds = 1 second
      });      
    
});

$(document).ready(function($) {
    // For Elementor Accordion, close all at the beginning
    $('.elementor-accordion .elementor-tab-title').removeClass('elementor-active');
    $('.elementor-accordion .elementor-tab-content').css('display', 'none');

});

$(document).ready(function() {
    // Sélectionnez l'élément avec l'attribut href
    var hrefValue = $('.mrb-product-first-image').attr('src');
    // Copier la valeur de href dans l'attribut data-src de l'image cible
    $('.mrb-product-first-image').attr('data-src', hrefValue);
});

$(document).ready(function() {
    // 1) Detect the width of the screen
    var screenWidth = $(window).width();
    // console.log('Largeur de l\'écran : ' + screenWidth + 'px');

    // 2) Height total of the menu
    // 2.1) Compute the number of categories (first colonne)
    var nbrMenu = $(this).find('.cbp-hrsub-tabs-names.cbp-tabs-names li').length;
    // 2.2) Compute the height total of the menu (29= height of a category + margin)
    var menuHeight = nbrMenu * 29 + 15 + 3;
    // 2.3) Compute height to the inner category menu
    var innerMenuHeight = menuHeight - 80;
    // console.log('nbrMenu : '+nbrMenu+' menuHeight '+menuHeight);

    // 3) Set height to the plane of the menu
    $('.cbp-hrmenu .cbp-tabs-container .tab-content').css('max-height', menuHeight+'px');

    // 4) For each catgory sub menu
    $('.tab-pane.cbp-tab-pane').each(function() {
      // 5.1) Set height to the inner category menu
      var listContainer = $(this).find('.menu_row.menu-element');
      listContainer.css('max-height', innerMenuHeight+'px');
      
      // 5.2) Get the number of category ans sub category to compute max height
      var numCat = listContainer.find('.cbp-menu-column.cbp-menu-element .cbp-categories-row').length;
      var numSousCat = listContainer.find('.cbp-menu-column.cbp-menu-element ul .cbp-category-link-w').length;

      // 5.3) Compute the max height of the plane 
      var height = Math.round((numCat * 50 + numSousCat * 35)/4);
    //   console.log('numCat : '+numCat+' numSousCat : '+numSousCat+' height :'+height);

      // 5.4) Compute of category with more than 10 sub-categories (big categories need more space)
      // PARAMETER 1 : BIGCAT --> 10 
      var bigCat = 0;
      (listContainer.find('.cbp-menu-column.cbp-menu-element')).each(function() {
        var listContainer2 = $(this).find('ul .cbp-category-link-w').length;
        // console.log('listContainer2 : '+listContainer2);
        if(listContainer2 > 10){
            bigCat +=1;
        }
      });
      if(bigCat>1) {      
        // PARAMETER 2 : BIGCAT --> 20px 
        height = height + (bigCat * 20);
        // console.log('bigCat : '+bigCat+' height '+height);
       }
  
      // 5.5) Compute the max height for responsive (need more space because long title take 2 or 3 lines)
      // PARAMETER 3 : ScreenWidth --> 1200 AND numSousCat -> 30
      if(screenWidth<1200 && numSousCat > 30 ){
        height = Math.round(height + (((1200-screenWidth)*1)));
        // console.log('resp : '+(1200-screenWidth)*1);
      }

      // 5.5) Set the max height
      // Only for category sub-menu
      if(numCat>0){
        if (height > innerMenuHeight) {
            listContainer.css('max-height', height+'px'); // Appliquer la hauteur sur le conteneur intermédiaire
            // listContainer.css('background-color', 'blue'); // Appliquer la hauteur sur le conteneur intermédiaire 
        } else {
            if(screenWidth>=1200){
                var diff = innerMenuHeight - height;
                if(diff>100){
                    height = height + 100;
                }
                //listContainer.css('max-height', height+'px');
            }
        }
      }
    });
  });

$(document).ready(function() {
    $('#mrbcontact-add-file').click(function(e){
        e.preventDefault();
        var fileUpload2 = $('.contact-form .form-group').has('#fileUpload2');
        var fileUpload3 = $('.contact-form .form-group').has('#fileUpload3');
        if(fileUpload2.is(':visible')){
            fileUpload3.show(); 
            $('#mrbcontact-add-file').hide();
        }
        if(fileUpload2.is(':hidden')){
            fileUpload2.show(); 
        }
    });
});